<template>
    <div>
        <TransitionRoot as="div" :show="programStore.order">
        <Dialog
                as="div"
                class="fixed inset-0 overflow-hidden dialog-app z-20"
                @close="programStore.order = false"
        >
            <div class="absolute inset-0 overflow-hidden">
                <DialogOverlay class="absolute inset-0"/>

                <div
                        class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-16"
                >
                    <TransitionChild
                            as="div"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0"
                            leave-to="translate-x-full"
                    >
                        <div class="pointer-events-auto w-screen max-w-[496px] h-full">
                            <form
                                    class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                            >
                                <div class="flex-1">
                                    <!-- Header -->
                                    <div class="px-4 py-8 sm:px-8">
                                        <div class="flex items-start justify-between space-x-3">
                                            <div class="space-y-1">
                                                <DialogTitle
                                                        class="text-2xl font-normal leading-8 md:text-head md:font-medium text-gray-900"
                                                >
                                                    {{ slotsStore.sendOrder ? "" : "Оставить заявку" }}
                                                </DialogTitle>
                                            </div>
                                            <div
                                                    class="flex h-7 relative md:-right-4 md:-top-3 items-center"
                                            >
                                                <button
                                                        type="button"
                                                        class="text-black hover:text-stone-500 outline-none"
                                                        @click="programStore.order = false"
                                                >
                                                    <span class="sr-only">Close panel</span>
                                                    <XIcon class="h-6 w-6" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <template v-if="!slotsStore.sendOrder">
                                        <!-- Divider container -->
                                        <div class="space-y-6 sm:space-y-0 sm:py-0">
                                            <template v-if="programStore.type === 'custom'">
                                                <DialogProgramTypeCustom></DialogProgramTypeCustom>
                                            </template>
                                            <template v-else-if="programStore.type === 'complex'">
                                                <DialogProgramTypeComplex></DialogProgramTypeComplex>
                                            </template>
                                            <DialogFormContacts type="program"></DialogFormContacts>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="pt-5 px-4 md:px-10">
                                            <DialogFormOrderSend/>
                                            <div class="mt-3 sm:mt-5"></div>
                                        </div>
                                        <div class="mt-5 sm:mt-6"></div>
                                    </template>
                                </div>

                                <!-- Action buttons -->
                                <template v-if="!slotsStore.sendOrder">
                                    <DialogProgramActions type="program"/>
                                </template>
                            </form>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
    </div>
</template>

<script>
import {createDateText, createTimeText} from "./../../../libs/date";
import {useSlots} from "./../../../store/slots";
import {useDocs} from "./../../../store/docs";

import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    Switch,
    SwitchGroup,
    SwitchLabel,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";

import {XIcon} from "@heroicons/vue/outline";
import {scrollTo} from "./../../../libs/ui";
import Actions from "./Actions";
import {usePrograms} from "./../../../store/programs";

export default {
    props: ["type"],
    setup(props) {
        const docsStore = useDocs();
        const slotsStore = useSlots();
        const programStore = usePrograms();

        return {
            docsStore,
            slotsStore,
            programStore,
            createDateText,
            createTimeText,
            scrollTo,
        };
    },

    data() {
        return {
            name: "Врач",
        };
    },
    watch: {
        date() {
        },
    },
    components: {
        Actions,
        Dialog,
        DialogTitle,
        TransitionRoot,
        TransitionChild,
        DialogOverlay,
        Switch,
        SwitchGroup,
        SwitchLabel,
        XIcon,
    },
};
</script>
