<template>
    <div>
        <div>
            <Loader></Loader>
        </div>
        <div class="bg-orange-50">
            <header>
                <Header></Header>
            </header>

            <div class="mt-4 sm:mt-2 overflow-x-hidden" style="min-height: 90vh">
                <slot/>
            </div>

            <TheModals/>
            <footer>
                <Footer/>
            </footer>
            <SlotDialog></SlotDialog>
            <SlotOrder></SlotOrder>
            <DialogProgramFirst></DialogProgramFirst>
            <Alert/>
            <client-only>
                <checkInternet></checkInternet>
            </client-only>
            <Promo20/>

            <AppDialog></AppDialog>
        </div>
    </div>
</template>

<script>
import Loader from "../components/Loader";
import {useClinics} from "~/store/clinics";
import {useUi} from "~/store/ui";
import {useSlots} from "~/store/slots";
import cookieHelper from "~/libs/region";
import {useRegion} from "~/store/app/region";

export default {
    components: {Loader},
    async mounted() {
        this.setRegionFromUrl();
        await this.getClinicId();

        window.logKeyPress = [];
        document.onkeyup = (event) => {
            window.logKeyPress.push(event.key);
            if (
                window.logKeyPress.slice(-5).join("") === "ащьшт" ||
                window.logKeyPress.slice(-5).join("") === "fomin"
            ) {
                console.log("debug mode");
                this.uiStore.debugMode = true;
                this.slotsStore.patient = {...this.slotsStore.patientDebug};
            }
            // if (
            //   window.logKeyPress.slice(-4).join("") === "test" ||
            //   window.logKeyPress.slice(-4).join("") === "еуые"
            // ) {
            //   console.log("set test patient");
            //   this.clientStore.setTestPatient();
            // }
        };
    },
    async setup() {
        const clinicsStore = useClinics();
        const slotsStore = useSlots();
        const uiStore = useUi();
        const route = useRoute()
        useHead(() => ({
            link: [
                {
                    rel: 'canonical',
                    href: 'https://kids.fomin-clinic.ru' + route.path,
                },
            ],
        }))

        if (!clinicsStore.listClinics.length) {
            await clinicsStore.fetchClinics();
        }

        const regionsWithClinics = clinicsStore.regionsWithClinics;
        const listRegions = clinicsStore.listRegions;

        return {
            uiStore,
            clinicsStore,
            slotsStore,
            regionsWithClinics,
            listRegions,
        };
    },
    methods: {
        setRegionFromUrl() {
            const regionStore = useRegion();
            const urlParams = new URLSearchParams(window.location.search);
            const regionId = urlParams.get("region");
            if (regionId) {
                cookieHelper.setRegionId(regionId);
                regionStore.setRegion();
            }
        },
        goHome() {
            window.location = window.location;
        },
        async getClinicId() {
            function getRegionClinicIdFromUrl() {
                const urlParams = new URLSearchParams(window.location.search);
                const regionParam = urlParams.get("region");

                switch (regionParam) {
                    case "ufa":
                        return "6e7ffdd9-5667-11ec-bb0f-d0e4dab58074";
                    case "krr":
                        return "2dbf05af-3186-11eb-94f2-6ab5df5ca2f5";
                    case "msk":
                        return "2fa0c897-bbd8-11ea-b901-dcf5058b959a";
                    case "sochi":
                        return "f94b5f03-65ad-11ed-953d-d01dbecae8b6";
                    case "spb":
                        return "74af85c9-5700-11eb-9505-3a56c320acf4";
                    default:
                        return null;
                }
            }

            let clinicId = getRegionClinicIdFromUrl();


            if (clinicId) {
                window.localStorage.setItem('clinicId', clinicId);
                this.clinicsStore.setClinicId(clinicId);
                this.clinicId = clinicId;

                function removeRegionParam() {
                    const currentUrl = window.location.href;
                    const url = new URL(currentUrl);
                    url.searchParams.delete('region');
                    const updatedUrl = url.toString();
                    window.history.replaceState({}, document.title, updatedUrl);
                }

                removeRegionParam();
            } else {
                if (window.localStorage) {
                    let clinicId = window.localStorage.getItem("clinicId");

                    if (clinicId === '43c0eb72-7224-11ed-953e-be947ca7f82c') {
                        clinicId = '2fa0c897-bbd8-11ea-b901-dcf5058b959a';
                    }

                    if (clinicId) {
                        this.clinicsStore.setClinicId(clinicId);
                        this.clinicId = clinicId;
                    } else {
                        this.open = true;
                    }
                }
            }
        },
    },
};
</script>

<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(0.1rem);
}
</style>
